'use strict';

angular.module('app.services')
  .factory('suggest', function ($http, $q, debounce, nixTrackApiClient) {
    // Typeahead directive does not play with debounced function, so it's the solution

    const query = debounce(function (endpoint, queryParams, deferred) {
      $http.get(endpoint, {params: queryParams})
        .success(function (tags) {
          deferred.resolve(tags);
        });
    }, 500);

    const suggest = function (endpoint, search, queryParams = {}) {
      search = search ? search.toString() : '';
      if (search.length < 3) {
        return $q.resolve([]);
      }

      const deferred = $q.defer();

      query(endpoint, _.merge({q: search}, queryParams), deferred);

      return deferred.promise;
    };

    return {
      brands:                   function (search, type = null) {
        return suggest('/api/suggest/brands', search, {type});
      },
      tags:                     function (search) {
        return suggest('/api/bulk-tag/suggest', search);
      },
      tags_exercise:            function (search) {
        return suggest('/api/suggest/tags-exercise', search);
      },
      similar_phrases:          function (search) {
        return suggest('/api/suggest/similar-phrases', search);
      },
      similar_exercise_phrases: function (search) {
        return suggest('/api/suggest/similar-exercise-phrases', search);
      },
      common_foods:             (function () {
        function query(search) {
          return nixTrackApiClient('/search/instant', {
            params: {
              query:   search,
              branded: false,
              self:    false,
              common:  true
            }
          })
            .then(response => response.data.common.map(f => f.food_name))
            .catch(() => []);
        }

        const debouncedQuery = debounce(function (search, resolve) {
          query(search).then(results => resolve(results));
        }, 300);

        return function debouncedSearchPromise(search) {
          return $q(resolve => {
            debouncedQuery(search, resolve);
          });
        }
      }()),
      legacy_brands:            function (search) {
        return suggest('/api/brand-recipes/brands', search, {limit: 20});
      }
    };
  });
