'use strict';

angular.module('app.controllers')
    .controller('BulkTagCtrl',
        function ($scope, $state, $http, ngTableParams,
                  $location, $q, Tag,
                  debounce, $notification, URLON,
                  endlessTotal, suggestTags) {
            let vm = $scope.vm = this;
            let qRaw;

            $scope.search = {
                filter:  {
                    'i.item_type': '2'
                },
                perform: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            $scope.checkAll = function () {
                _.forEach($scope.tableParams.data, function (item) {
                    item.$checked = $scope.checkAll.to;
                });
            };

            $scope.checkAll.getCheckedNumber = function () {
                let checked = 0;

                if ($scope.tableParams) {
                    for (let i = 0; i < $scope.tableParams.data.length; i += 1) {
                        if ($scope.tableParams.data[i].$checked) {
                            checked += 1;
                        }
                    }
                }

                return checked;
            };

            let tagId = $location.search().tagId;
            let tagPromise;

            if (tagId) {
                tagPromise = Tag.get({id: tagId}).$promise;

                tagPromise.then(tag => {
                    $scope.addTag.text = tag.name;
                    $scope.addTag.tag  = vm.tag = tag;
                });
            } else {
                tagPromise = $q.resolve(null);
            }

            tagPromise.then(() => {
                let q;

                qRaw = $location.search().q;

                if (!qRaw && vm.tag) {
                    qRaw = vm.tag.cpg_search_filter;
                    if (qRaw) {
                        $location.search('q', qRaw);
                    }
                }

                if (qRaw) {
                    q = URLON.parse(qRaw);

                    $scope.search.filter['i.item_type'] = q.filter['i.item_type'];
                }

                $scope.tableParams = new ngTableParams(
                    {
                        page:    1,            // show first page
                        count:   $state.params.perPage,           // count per page
                        sorting: q && q.sorting || $state.params.order,
                        filter:  q ? _.pick(q.filter, ['upc', 'i.item_name', 'b.name', 'has_image', 'tagged', 'i.nf_serving_size_unit']) : {tagged: '0'}
                    },
                    {
                        counts:  [],
                        total:   0, // length of data
                        getData: function ($defer, params) {
                            const requestParams = {
                                limit:     params.count(),
                                offset:    (params.page() - 1) * params.count(),
                                orderBy:   _.pairs(params.sorting())[0].join('.'),
                                filter:    _.extend({min_scan_count: 11}, $scope.search.filter, params.filter()),
                                skipCount: true
                            };

                            for (let i = 0; i < this.textFilters.length; i += 1) {
                                if (requestParams.filter[this.textFilters[i]]) {
                                    // disallow text filters to be shorter than 3 symbols
                                    if (requestParams.filter[this.textFilters[i]].length < 3) {
                                        $defer.reject();
                                        return;
                                    } else {
                                        this.load = true;
                                    }
                                }
                            }


                            if (!this.load) {
                                this.load = true;
                                $defer.reject();
                                return;
                            }


                            $http.get('/api/items', {params: requestParams}).then((response) => {
                                const data = response.data;
                                params.total(endlessTotal(params, data.results.length));
                                $defer.resolve(data.results);
                            });

                        },
                        load:        $state.params.initialLoad,
                        textFilters: ['upc', 'i.item_name', 'b.name', 'i.nf_serving_size_unit']
                    }
                );

                $scope.$watch(
                    function () {
                        return JSON.stringify($scope.search) +
                            JSON.stringify($scope.tableParams.filter()) +
                            JSON.stringify($scope.tableParams.sorting());
                    },
                    function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            qRaw = URLON.stringify(_.merge(
                                {},
                                $scope.search,
                                {filter: $scope.tableParams.filter()},
                                {sorting: $scope.tableParams.sorting()}
                            ));
                            $location.search('q', qRaw);
                        }
                    }
                );
            });

            $scope.getTags = suggestTags;

            $scope.addTag = {
                text:   '',
                tag:    null,
                error:  null,
                select: function (tag) {
                    this.tag = tag;
                    $location.search('tagId', tag.id);
                },
                submit: function () {
                    $http.post('/api/bulk-tag/assign',
                        {
                            tagId: this.tag.id,
                            items: _.filter($scope.tableParams.data, item => !!item.$checked).map(i => i._id),
                            q:     qRaw
                        })
                        .then(function () {
                            $scope.tableParams.reload();
                            $scope.checkAll.to = false;
                        })
                        .catch(function () {
                            $notification.error('Tag assign failed');
                        });
                },
                clear:  function () {
                    $scope.addTag.tag   = '';
                    $scope.addTag.text  = null;
                    $scope.addTag.error = null;
                    $location.search('tagId', null);
                }
            };


        }
    );
